<template>
  <div class="main-box">
    <div class="con-box">
      <div class="title">
        <div :class="{ 'tab-active': tabIndex == 1, 'tab': tabIndex != 1 }" @click="onTab(1)">业务受理</div>
        <div :class="{ 'tab-active': tabIndex == 2, 'tab': tabIndex != 2 }" @click="onTab(2)">贷款完成</div>
      </div>
      <div v-if="tabIndex == 1">
        <div class="search-box">
          <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="search-con">
              <div class="con-item">
                <a-form-item label="服务种类">
                  <a-select v-model:value="serviceCategory" placeholder="请选择服务种类">
                    <a-select-option v-for="(sItem, sii) in serviceList" :key="sii" :value="sItem.BIANMA">{{sItem.NAME}}</a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="con-item">
                <a-form-item label="产品名称">
                  <a-input v-model:value="chanpinmingcheng"  placeholder="请输入产品名称" />
                </a-form-item>
              </div>
              <div class="con-item">
                <a-form-item label="企业名称">
                  <a-input v-model:value="qiyemingcheng"  placeholder="请输入企业名称" />
                </a-form-item>
              </div>
              <div class="con-item" style="visibility: hidden;">
                <a-form-item label="受理状态">
                  <a-select v-model:value="demandState" placeholder="请选择受理状态">
                    <a-select-option value="1">受理状态1</a-select-option>
                    <a-select-option value="2">受理状态2</a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="con-itemB">
                <a-button @click="getList(1)">确定筛选</a-button>
              </div>
            </div>
          </a-form>
        </div>
      </div>
      <div v-if="tabIndex == 1">
        <div class="content-box">
          <a-table :columns="columnsA" :data-source="sList" :loading="loading" :pagination="pagination" @change="onPage" :scroll="{ x: 1800 }">
            <template #operation="{record}">
              <a-button type="primary" style="margin: 0 15px;" @click="downloadDataPackage (record.ENTERPRISE_ID)">下载数据包</a-button>
              <a-button type="primary" @click="onStatusShow(record)" :style="{visibility: (record.AUDIT_NAME === '已结束' || record.CONFIRM_YN==='R'|| record.AUDIT_NAME === '已拒绝' ) ? 'hidden' : ''}">状态</a-button>
              <a-button type="primary" style="margin: 0 15px;" :style="{visibility: (record.AUDIT_NAME === '已结束'|| record.CONFIRM_YN==='R') ? 'hidden' : ''}" @click="onShowUname(record)">维护</a-button>
              <a-button type="primary" @click="$router.push({path: '/PersonalLayout/MyServiceDetails', query: { id: record.lid }})">详情</a-button>
              <a-button type="primary" style="margin: 0 15px 0;"  @click="$router.push({path: '/PersonalLayout/JgDiagnosisHistory', query: { ENTERPRISE_ID: record.ENTERPRISE_ID }})">报告</a-button>
              <a-button type="primary" style="margin: 0 15px;" :style="{visibility: record.CREATION_STATUS === 'N'? 'hidden' : ''}" @click="reject(record.ENTERDEPTHREPORT_ID)">驳回报告</a-button>
              <a-button type="primary" @click="deletes(record.lid)" :style="{visibility: record.shoulizhuangtai === '已结束'|| record.xuqiuzhuangtai === '企业' ? 'hidden' : ''}">删除</a-button>
            </template>
          </a-table>
        </div>
      </div>
      <div v-else>
        <div v-if="tabIndex == 2">
          <div class="search-box">
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
              <div class="search-con">
                <div class="con-item">
                  <a-form-item label="企业名称">
                    <a-input v-model:value="ENTERPRISE_NAME"  placeholder="请输入企名称" />
                  </a-form-item>
                </div>
                <div class="con-item">
                  <a-form-item label="放款银行">
                    <a-input v-model:value="BANK_LOAN"  placeholder="请输入放款银行名称" />
                  </a-form-item>
                </div>
                <div class="con-item">
                  <a-form-item label="放款额度">
                    <a-input v-model:value="RECEIVE_MONEY"  placeholder="请输入放款额度" />
                  </a-form-item>
                </div>
                <div class="con-item">
                  <a-form-item label="放款利率">
                    <a-input v-model:value="INTEREST_RATE"  placeholder="请输入放款利率" />
                  </a-form-item>
                </div>
                <div class="con-itemB">
                  <a-button @click="getList(2)">确定筛选</a-button>
                </div>
              </div>
            </a-form>
          </div>
          <div v-if="tabIndex == 2">
            <div class="content-box">
              <!--                    贷款完成列表-->
              <a-table :columns="columnsB" :data-source="sList" :loading="loading" :pagination="pagination" @change="onPage" ></a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tabIndex == 1">
    <a-modal
            :visible="uNameShow"
            title="经办人员维护"
            @ok="onSet"
            ok-text="确定"
            cancel-text="取消"
            @cancel="uNameShow = false"
    >
      <a-form-item label="人员姓名">
        <a-input v-model:value="renyuanxingming" placeholder="请输入人员姓名" />
      </a-form-item>
      <a-form-item label="联系方式">
        <a-input v-model:value="lianxifangshi" placeholder="请输入联系方式" />
      </a-form-item>
    </a-modal>
    <a-modal
            :visible="statusShow"
            @cancel="statusShow = false"
            title="受理状态"
            :footer="null"
    >
      <div style="text-align: center;">{{tips}}</div>
      <a-form-item label="放款额度" v-if="FKEDSHOW" >
        <a-input v-model:value="FKED"  placeholder="请输入放款额度" style="width: calc(100% - 30px)"></a-input>万元
      </a-form-item>
      <a-form-item label="放款利率" v-if="FKLLSHOW" >
        <a-input v-model:value="FKLL"  placeholder="请输入放款利率" style="width: calc(100% - 30px)"></a-input>%
      </a-form-item>
      <a-form-item label="放款银行" v-if="FKLLSHOW" >
        <a-input v-model:value="FBANK"  placeholder="请输入放款银行" style="width: calc(100% - 30px)"></a-input>
      </a-form-item>
      <a-textarea
              v-model:value="shuomingneirong"
              placeholder="请输入说明内容"
              style="margin: 20px 0;height: 150px;"
      />
      <div style="text-align: center;padding: 0 20%;display: flex;justify-content: space-between;">
        <a-button @click="statusShow = false, FKED = '',FKLL = '' ">取消</a-button>
        <a-button @click="onSubmit">确认</a-button>
        <a-button style="border: 1px solid red;color: red;" @click="onSetAuditFlag(listItem, -10)">拒绝</a-button>
      </div>
    </a-modal>
  </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  export default defineComponent({
    computed: {
      ...mapGetters(['orgId', 'loginType', 'orgName'])
    },
    setup() {
      const columnsA = [
        {
          title: '序号',
          dataIndex: 'key',
          key: 'key',
          align: 'center',
          width: 60,
          fixed:'left'
        },
        {
          title: '企业名称',
          width: 200,
          dataIndex: 'enterpriseName',
          key: 'enterpriseName',
          align: 'center'
        },
        {
          title: '服务种类',
          dataIndex: 'serviceItem',
          key: 'serviceItem',
          align: 'center'
        },
        {
          title: '产品名称',
          dataIndex: 'pName',
          key: 'pName',
          align: 'center'
        },
        {
          title: '担保方式',
          dataIndex: 'danbaofangshi',
          key: 'danbaofangshi',
          align: 'center'
        },
        {
          title: '需求额度',
          dataIndex: 'xuqiuedu',
          key: 'xuqiuedu',
          align: 'center'
        },
        {
          width: 150,
          title: '放款额度(利率)',
          dataIndex: 'fangkuanedu',
          key: 'fangkuanedu',
          align: 'center'
        },
        {
          title: '对接方式',
          dataIndex: 'duijiefangshi',
          key: 'duijiefangshi',
          align: 'center'
        },
        {
          title: '需求状态',
          width: 140,
          dataIndex: 'xuqiuzhuangtai',
          key: 'xuqiuzhuangtai',
          align: 'center'
        },
        {
          title: '经办人员',
          dataIndex: 'jingbanrenyuan',
          key: 'jingbanrenyuan',
          align: 'center',
          width:130
        },
        {
          title: '受理状态',
          dataIndex: 'shoulizhuangtai',
          key: 'shoulizhuangtai',
          align: 'center',
          // slots: {
          //     customRender: 'shoulizhuangtai'
          // }
        },
        {
          width: 680,
          fixed: 'right',
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ]
      const columnsB = [
        {
          title: '序号',
          dataIndex: 'key',
          key: 'key',
          align: 'center',
          width: 60,
          fixed:'left'
        },
        {
          title: '企业名称',
          width: 200,
          dataIndex: 'enterpriseName',
          key: 'enterpriseName',
          align: 'center'
        },
        {
          title: '服务种类',
          dataIndex: 'serviceItem',
          key: 'serviceItem',
          align: 'center'
        },
        {
          title: '产品名称',
          dataIndex: 'pName',
          key: 'pName',
          align: 'center'
        },
        {
          title: '需求额度',
          dataIndex: 'xuqiuedu',
          key: 'xuqiuedu',
          align: 'center'
        },
        {
          width: 150,
          title: '放款额度(万)',
          dataIndex: 'receivemoney',
          key: 'receivemoney',
          align: 'center'
        },
        {
          title: '放款利率',
          dataIndex: 'interestrate',
          key: 'interestrate',
          align: 'center'
        },
        {
          title: '对接方式',
          dataIndex: 'duijiefangshi',
          key: 'duijiefangshi',
          align: 'center'
        },
        {
          title: '对接机构名称',
          dataIndex: 'bankchildname',
          key: 'bankchildname',
          align: 'center'

        },
        {
          title: '放款银行',
          dataIndex: 'bankloan',
          key: 'bankloan',
          align: 'center'
        },
        {
          title: '业务结束日期',
          dataIndex: 'overdate',
          key: 'overdate',
          align: 'center'
        },
        {
          title: '需求状态',
          width: 140,
          dataIndex: 'xuqiuzhuangtai',
          key: 'xuqiuzhuangtai',
          align: 'center'
        },
        {
          title: '受理状态',
          dataIndex: 'shoulizhuangtai',
          key: 'shoulizhuangtai',
          align: 'center',
        },
      ]
      return {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 16
        },
        columnsA,
        columnsB,
      }
    },
    data () {
      return {
        tabIndex: 1,
        tableId: 2,
        sList: [],
        serviceCategory: undefined,
        qiyemingcheng: undefined,
        chanpinmingcheng: undefined,
        demandState: undefined,
        keywords: '',
        serviceList: [],
        loading: false,
        pagination: {},
        page: 1,
        uNameShow: false,
        renyuanxingming: '',
        lianxifangshi: '',
        linId: '',
        statusShow: false,
        shuomingneirong: '',
        tips: '',
        listItem: '',
        FKED: '',
        FKEDSHOW: false,
        FKLLSHOW: false,
        FKLL: '',
        FBANK : '',
        BANK_LOAN:'',
        RECEIVE_MONEY:'',
        INTEREST_RATE:'',
        OVER_DATE:'',
        ENTERPRISE_NAME:'',
        tables: '',
      }
    },
    methods: {
      onSubmit () {
        if (this.listItem.AUDIT_STATUS == 0) {
          this.onSetAuditFlag(this.listItem, 1)
        } else if (this.listItem.AUDIT_STATUS == 1) {
          this.onSetAuditFlag(this.listItem, 20)
        } else if (this.listItem.AUDIT_STATUS == 20) {
          this.onSetAuditFlag(this.listItem, 30)
        }
      },
      downloadDataPackage (eid) {
        window.location.href = this.$downloadDataPackage+'?ENTERPRISE_ID='+eid
      },
      reject (enterdepthreportId) {
        this.$store.dispatch('reject', {
          ENTERDEPTHREPORT_ID: enterdepthreportId,
          CREATION_STATUS: 'N',
          tm: new Date().getTime()
        }).then(res => {
          if (res.result === 'success') {
            this.$message.success('驳回成功！')
              this.getList(1)
          } else {
            this.$message.error('系统异常，驳回失败，请稍后重试！')
          }
        })
      },
      onStatusShow (item) {
        if (item.CONFIRM_YN === 'N' && item.AUDIT_NAME === '未开始') {
          this.$message.info('等待企业确认！')
          return false
        } else if (item.AUDIT_NAME === '未开始') {
          this.tips = '业务是否进入受理中？'
        } else if (item.AUDIT_NAME === '受理中') {
          this.tips = '业务是否进入审批中？'
        } else if (item.AUDIT_NAME === '审批中') {
          this.FKEDSHOW = true
          this.FKLLSHOW = true
          this.tips = '业务是否进入结束中？'
        } else if (item.AUDIT_NAME === '已结束') {
          this.$message.info('业务已结束！')
          return false
        } else if (item.AUDIT_NAME === '已拒绝') {
          this.$message.info('企业已拒绝此业务！')
          return false
        }
        this.listItem = item
        this.statusShow = true
      },
      //删除
      deletes(lid){
        //需要弹窗提示
        this.$store.dispatch('deleteBusiness', {
          LINKENTERPROC_ID: lid,
          tm: new Date().getTime()
        }).then(res => {
          if (res.result === 'success') {
            this.$message.success('删除成功！')
            this.getList(1)
            this.reload()
          } else {
            this.$message.error('系统异常，删除失败！')
          }
        })

      },
      onShowUname (item) {
        if (item.CONFIRM_YN === 'N' && item.AUDIT_NAME === '未开始') {
          this.$message.info('等待企业确认！')
          return false
        }
        this.linId = item.lid
        this.uNameShow = true
      },
      onSet () {
        if (this.renyuanxingming === '') {
          this.$message.info('人员姓名不能为空！')
          return false
        } else if (this.lianxifangshi === '') {
          this.$message.info('联系方式不能为空！')
          return false
        }
        this.$store.dispatch('setPersonInfo', {
          LINKENTERPROC_ID: this.linId,
          ACCOUEXECU_UNAME: this.renyuanxingming,
          ACCOUEXECU_PHONE: this.lianxifangshi,
          tm: new Date().getTime()
        }).then(res => {
          if (res.result === 'success') {
            this.$message.success('设置经办人员成功！')
            this.uNameShow = false
              this.getList(1)
            this.renyuanxingming = ''
            this.lianxifangshi = ''
          } else {
            this.$message.error('系统异常，设置经办人员失败，请稍后重试！')
          }
        })
      },
      // 修改状态
      onSetAuditFlag (item, sta) {
        let fangkuanedu = 0, fangkuanlilv = 0, fangkuanbank = ''
        if (sta == 1) {
          fangkuanedu = 0
          fangkuanlilv = 0
        } else if (sta == 20) {
          fangkuanedu = 0
          fangkuanlilv = 0
        } else if (sta == 30) {
          fangkuanedu = this.FKED
          fangkuanlilv = this.FKLL
          fangkuanbank = this.FBANK
        }
        this.$store.dispatch('setAuditFlag', {
          LINKENTERPROC_ID: item.lid,
          AUDIT_STATUS: sta,
          REMARK: this.shuomingneirong,
          AUDIT_UNAME: this.orgName,
          RECEIVE_MONEY: fangkuanedu,
          INTEREST_RATE: fangkuanlilv,
          BANK_LOAN: fangkuanbank,
          tm: new Date().getTime()
        }).then(res => {
          if (res.result === 'success') {
            if (sta == 1) {
              this.$message.success('业务已成功受理！')
            } else if (sta == 20) {
              this.$message.success('业务已进入审批中！')
            } else if (sta == 30) {
              this.$message.success('业务已结束！')
              this.FKLLSHOW = false
              this.FKEDSHOW = false
            } else if (sta == -10) {
              this.$message.success('业务已拒绝！')
            }
            this.statusShow = false
            this.shuomingneirong = ''
            this.FKEDSHOW = false
            this.FKED = ''
            this.FKLL = ''
              this.getList(1)
          } else {
            this.$message.error('系统异常！')
          }
        })
      },
      // 翻页
      onPage (pagination) {
        this.page = pagination.current
        if(this.tabIndex ===1){
          this.getList(1)
        }else if(this.tabIndex ===2){
          this.getList(2)
        }
      },
      //获取业务受理
      getList (type){
        this.loading = true
        this.sList = []
        let params = {}
        if(type === 1){
          params = {
            showCount: 10,
            currentPage: this.page,
            BANKCHILD_ID: this.orgId,
            SERVICE_COLUMN: this.serviceCategory,
            PRODUCT_NAME: this.chanpinmingcheng,
            ENTERPRISE_NAME: this.qiyemingcheng,
            tables: 1
          }

        }else if(type === 2){
          params = {
            showCount: 10,
            currentPage: this.page,
            BANKCHILD_ID: this.orgId,
            ENTERPRISE_NAME: this.ENTERPRISE_NAME,
            BANK_LOAN: this.BANK_LOAN,
            RECEIVE_MONEY: this.RECEIVE_MONEY,
            INTEREST_RATE: this.INTEREST_RATE,
            OVER_DATE: this.OVER_DATE,
            tables: 2

          }
        }
        this.$store.dispatch('getSuccessList1', {...params}).then(res => {
          this.loading = false
          if (res.result === 'success') {
            let datas = res.varList
            if (datas.length != 0) {
              this.pagination.total = res.page.totalResult
              for (let i in datas) {
                let SLZT = ''
                if (datas[i].AUDIT_STATUS == 0) {
                  SLZT = '未受理'
                } else if (datas[i].AUDIT_STATUS == 1) {
                  SLZT = '受理中'
                } else if (datas[i].AUDIT_STATUS == 20) {
                  SLZT = '审批中'
                } else if (datas[i].AUDIT_STATUS == 30) {
                  SLZT = '已完成'
                } else if (datas[i].AUDIT_STATUS == -10) {
                  SLZT = '已拒绝'
                }
                this.sList.push({
                  lid: datas[i].LINKENTERPROC_ID,
                  ENTERPRISE_ID: datas[i].ENTERPRISE_ID,
                  key: parseInt(i) + 1,
                  enterpriseName: datas[i].ENTERPRISE_NAME,
                  pName: datas[i].PRODUCT_NAME,
                  danbaofangshi: datas[i].GUARANTEE_METHOD,
                  serviceItem: datas[i].SERVICE_COLUMN,
                  duijiefangshi: datas[i].LINK_WAY === 'BLANK' ? '机构对接' : datas[i].LINK_WAY === 'OWN' ? '企业申请' : '系统对接',
                  shoulizhuangtai: datas[i].AUDIT_NAME,
                  bankchildname: datas[i].BANKCHILD_NAME,
                  bankloan: datas[i].BANK_LOAN,
                  overdate: datas[i].OVER_DATE,
                  receivemoney: datas[i].RECEIVE_MONEY,
                  interestrate:  (datas[i].INTEREST_RATE ? (datas[i].INTEREST_RATE + '%') : ''),

                  LINK_WAY: datas[i].LINK_WAY,
                  tables: datas[i].tabIndex,
                  CONFIRM_YN: datas[i].CONFIRM_YN,
                  xuqiuzhuangtai: datas[i].CONFIRM_YN === 'Y' ? '企业已确认' : datas[i].CONFIRM_YN === 'R' ? '企业已拒绝' : '企业待确认',
                  jingbanrenyuan: (datas[i].ACCOUEXECU_UNAME ? datas[i].ACCOUEXECU_UNAME : '') + ' ' + (datas[i].ACCOUEXECU_PHONE ? datas[i].ACCOUEXECU_PHONE : ''),
                  xuqiuedu: datas[i].QUOTA_E + '万',
                  fangkuanedu: (datas[i].RECEIVE_MONEY ? (datas[i].RECEIVE_MONEY + '万'+(datas[i].INTEREST_RATE ?'(':'')) : '') + (datas[i].INTEREST_RATE ? (datas[i].INTEREST_RATE + '%)') : ''),

                  AUDIT_NAME: datas[i].AUDIT_NAME,
                  AUDIT_STATUS: datas[i].AUDIT_STATUS,
                  CREATION_STATUS: datas[i].CREATION_STATUS,
                  ENTERDEPTHREPORT_ID: datas[i].ENTERDEPTHREPORT_ID
                })
              }
            } else {
              this.sList = []
              this.pagination.total = 0
            }
          } else {
            this.sList = []
            this.pagination.total = 0
            this.$message.error('系统异常，获取列表失败！')
          }
        })
      },
      getDate () {
        let nowDate = new Date()
        let y = nowDate.getFullYear()
        let m = nowDate.getMonth() + 1
        let d = nowDate.getDate()
        return y + '-' + m + '-' + d
      },
      getSelectList () {
        // 获取服务项下拉选项列表
        this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: '5e3766b8ee284c929c72cbfeba325b3b', tm: new Date().getTime() }).then(res => {
          if (res.result === 'success') {
            this.serviceList = res.list
          } else {
            this.serviceList = []
          }
        })
      },
      onTab (i) {
        this.tabIndex = i
        if (i == 1) {
          this.getList (1)
        } else if(i == 2){
          this.getList(2)
        } else {
          this.getProductList()
        }
      }
    },
    created () {
      if (this.orgId === '' || this.orgId == null) {
        this.$message.info('请先登录后才能进入')
        this.$router.push('/')
        return false
      } else if (this.loginType !== 'jigou') {
        this.$message.info('只有金融机构账号才能进入')
        this.$router.push('/')
        return false
      }
      this.getList(this.tabIndex)
      // 获取下拉选项列表
      this.getSelectList()
    }
  })
</script>
<style lang="less" scoped>
  .content-box ::v-deep(.ant-table-thead){
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
  }
  .content-box ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
  }

</style>
<style lang="less" scoped>
  .main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
      padding: 15px;
      background-color: @color-ff;
      box-shadow: 0px 0px 10px #929292;
      border-radius: 5px;
      margin: 20px 0;
      position: relative;
      .title{
        font-size: @font-lg;
        padding: 0px 10px;
        padding-bottom: 15px;
        border-bottom: @border-base;
        display: flex;
        align-items: center;
        .tab {
          padding: 2px 15px;
          color: #A7A7A7;
          cursor: pointer;
        }
        .tab-active{
          padding: 2px 15px;
          color: #ffffff;
          cursor: pointer;
          background: @color-blue; /* 标准语法 */
        }
      }
      .search-box{
        .search-con{
          padding-top: 15px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .con-item{
            width: 30%;
          }
          .con-itemC{
            display: flex;
            justify-content: space-between;
            .ant-form-item{
              width: 70%;
            }
            .con-itemB{
              width: 30%;
            }
          }
          .con-itemA{
            width: 25%;
          }
          .con-itemB{
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 15%;
            ::v-deep(.ant-btn){
              padding:0 32px;
              color: #ffffff;
              background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
              background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
              background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
              background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
            }
          }
        }
      }
      .content-box{
        font-size: 16px;
      }
    }
    .con-box::before{
      content: '';
      position: absolute;
      left: -3px;
      top: 10px;
      width: 6px;
      height: 40px;
      background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
      background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
      background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
      background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
    .product-box{
      .product-con{
        padding: 20px;
        background-color: @color-ff;
        border-radius: 5px;
        li{
          display: flex;
          justify-content: space-between;
          padding: 20px 0;
          border-bottom: @border-base;
          .item-left{
            width: 384px;
            img{
              width: 100%;
              height: 215px;
            }
          }
          .item-right{
            width: 880px;
            .item-right-top{
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 10px;
              p{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0;
                font-size: 48px;
                font-weight: bold;
                img{
                  margin-left: 20px;
                  width: 150px;
                  height: 40px;
                }
              }
            }
            .item-right-bot{
              display: flex;
              justify-content: start;
              flex-wrap: wrap;
              border-top: @border-ea;
              padding-top: 10px;
              p{
                width: 290px;
                padding:5px;
                margin-bottom: 0;
                font-size: @font-lg;
                line-height: 30px;
                .sp-special{
                  color: @color-blue;
                  font-weight: bold;
                }
              }
            }
          }
        }
        li:first-child{
          padding-top: 0;
        }
      }
    }

    .page-box{
      padding: 15px 20px;
      background-color: @color-ff;
      border-radius: 5px;
      position: relative;
      .ant-pagination{
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center;
      }
    }
    .con-item1{
      margin: 25px 20px 0px 0px;

    }
  }
</style>
